.popup {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.popup_infobox{
    text-align: center;
}

.btn-div{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.marginTop20 {
    margin-top:20px;
}